import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  ViewChildren,
  ViewChild,
  QueryList,
  ChangeDetectorRef
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { GridConfiguration } from "./configuration/grid-configuration";
import { GridColumnConfiguration } from "./configuration/grid-column-configuration";
import { GetDialogDataForColumnConfiguration } from "./configuration/grid-dialog";
import { GridHyperlinkColumnConfiguration } from "./configuration/grid-hyperlink-column-configuration";
import { GridBooleanColumnConfiguration } from "./configuration/grid-boolean-column-configuration";
import { GridMultiValueColumnConfiguration } from "./configuration/grid-multi-value-custom-configuration";
import { GridFormatDateColumnConfiguration } from "./configuration/grid-format-date";
import { GridAffectedColumnConfiguration } from "./configuration/grid-affected-column-configuration";
import { GridLinkButtonColumnConfiguration } from "./configuration/grid-linkbutton-column-configuration";
import { GridHtmlColumnConfiguration } from "./configuration/grid-html-column-configuration";
import { GridCheckLimitColumnConfiguration } from "./configuration/grid-check-limit-column-configuration";
import { GridActionButtonColumnConfiguration } from "./configuration/grid-action-button-column-configuration";
import { GridActions } from "./configuration/grid-action-button";
import { Subject } from "rxjs";
import { PaginationData } from "./pagination-data";
import { GridQuantityColumnConfiguration } from "./configuration/grid-quantity-column-configuration";
import { EscapeCharacterPipe } from "./../../../pipes/escapeCharacter/escape-character.pipe";
import { GridMenuColumnConfiguration } from "./configuration/grid-menu-column-configuration";
import { GridMenuItem } from "./configuration/grid-menu-item";

@Component({
  selector: "rn-grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
})
export class GridComponent implements OnInit, AfterViewInit {
  @ViewChild("navButtons", { static: false }) navButtons: ElementRef;
  @ViewChildren(MatMenuTrigger) triggers: QueryList<MatMenuTrigger>;

  @Input() set Configuration(config: GridConfiguration) {
    this.configuration = config;
    this.configuration.CurrentPageNumber.subscribe((n) => {
      this.currentPageNum = n;
      this.changeDetectorRef.detectChanges();
    });
  }
  @Input() UniqueId?: number;
  @Input() clearSelected: Subject<void>;
  @Output() public rowClicked: EventEmitter<any> = new EventEmitter();
  @Output() rowSelected: EventEmitter<any> = new EventEmitter();
  @Output() userRowClicked: EventEmitter<any> = new EventEmitter();
  @Output() falseyValueClicked: EventEmitter<any> = new EventEmitter();
  @Output() actionButtonClicked: EventEmitter<any> = new EventEmitter();

  selection: any[];
  selector: any[];
  paginatorData: PaginationData[];

  public configuration: GridConfiguration;
  private navButtonsLoaded = false;

  constructor(
    private escapeCharacterPipe: EscapeCharacterPipe,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.clearSelected) {
      this.clearSelected.subscribe(() => {
        this.selection = [];
        this.changeDetectorRef.detectChanges();
      });
    }

    this.configuration.CurrentPageNumber.subscribe(() => {
      this.paginatorData = this.getPaginatorData();
      this.changeDetectorRef.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    if (this.navButtons) {
      this.navButtons.nativeElement.firstElementChild.focus();
      this.navButtons.nativeElement.firstElementChild.blur();
      this.changeDetectorRef.detectChanges();
    }
  }

  public GetHyperlinkHrefDataForColumn(
    col: GridColumnConfiguration,
    dataRow: any,
  ): string {
    const linkCol = col as GridHyperlinkColumnConfiguration;
    if (linkCol) {
      return linkCol.GetHref(dataRow);
    }
    return "";
  }

  public GetHyperlinkTextDataForColumn(
    col: GridColumnConfiguration,
    dataRow: any,
  ): string {
    const linkCol = col as GridHyperlinkColumnConfiguration;
    if (linkCol) {
      return linkCol.GetLinkText(dataRow);
    }
    return "";
  }

  public CallLinkButtonAction(
    $event,
    col: GridColumnConfiguration,
    dataRow: any,
  ): void {
    $event.stopPropagation();
    const linkCol = col as GridLinkButtonColumnConfiguration;
    if (linkCol) {
      linkCol.GetAction(dataRow);
    }
  }

  public IsMenuItemEnabled(
    col: GridColumnConfiguration,
    item: GridMenuItem,
    dataRow: any,
  ): boolean {
    const menuColumn = col as GridMenuColumnConfiguration;
    if (menuColumn && menuColumn.IsMenuItemEnabled) {
      return menuColumn.IsMenuItemEnabled(item, dataRow);
    }
    return true;
  }

  public IsMenuButtonEnabled(col: GridColumnConfiguration): boolean {
    const menuColumn = col as GridMenuColumnConfiguration;
    if (menuColumn && menuColumn.IsMenuButtonEnabled) {
      return menuColumn.IsMenuButtonEnabled();
    }
    return true;
  }

  public CallMenuButtonAction(
    $event: any,
    col: GridColumnConfiguration,
    item: GridMenuItem,
    dataRow: any,
  ): void {
    $event.stopPropagation();
    const menuColumn = col as GridMenuColumnConfiguration;
    if (item) {
      const openTrigger = this.triggers.filter((trigger) => trigger.menuOpen);
      item.action(dataRow, openTrigger[0]);
      if (menuColumn.AutoCloseMenu && openTrigger.length > 0) {
        openTrigger[0].closeMenu();
      }
    }
  }

  public GetLinkButtonTextDataForColumn(
    col: GridColumnConfiguration,
    dataRow: any,
  ): string {
    const linkCol = col as GridLinkButtonColumnConfiguration;
    if (linkCol) {
      return linkCol.GetLinkText(dataRow);
    }
    return "";
  }

  public GetHtmlDataForColumn(
    col: GridColumnConfiguration,
    dataRow: any,
  ): string {
    const htmlCol = col as GridHtmlColumnConfiguration;
    if (htmlCol) {
      return htmlCol.GetHtmlDataForColumn(dataRow);
    }
    return "";
  }

  public GetBooleanDataForColumn(
    col: GridBooleanColumnConfiguration,
    dataRow: any,
  ): string {
    return col.CustomDataRetrieval(dataRow) == "true"
      ? col.trueValue
      : col.falseValue;
  }

  public IsBooleanValueFalsey(
    col: GridBooleanColumnConfiguration,
    dataRow: any,
  ) {
    return col.CustomDataRetrieval(dataRow) !== "true";
  }

  public GetMultiValueDataForColumn(
    col: GridMultiValueColumnConfiguration,
    dataRow: any,
  ): string {
    let htmlString = "";
    htmlString += col.isLink
      ? "<a class='rn-text-link' href='" + col.GetHref(dataRow) + "'>"
      : "";
    for (let c = 0; c < col.fieldNames.length; c++) {
      const transformedColData: string = this.escapeCharacterPipe.transform(
        dataRow[col.fieldNames[c]],
      );
      htmlString +=
        "<span class='" + col.classNames[c] + "'>" + transformedColData;
      +"</span>";
    }
    htmlString += col.isLink ? "</a>" : "";
    return htmlString;
  }

  public GetAffectedDataForColumn(
    col: GridAffectedColumnConfiguration,
    dataRow: any,
  ): string {
    let htmlString = "";
    htmlString = col.getHtml(dataRow);
    return htmlString;
  }

  setSelection($event): void {
    this.rowSelected.emit(this.selection);
  }

  userRowClickHandler(dataRow: any): void {
    this.userRowClicked.emit(dataRow);
  }

  falseyValueClickHandler(dataRow: any): void {
    this.falseyValueClicked.emit(dataRow);
  }

  public GetDateValueDataForColumn(
    col: GridColumnConfiguration,
    dataRow: any,
  ): string {
    const linkCol = col as GridFormatDateColumnConfiguration;
    if (linkCol) {
      return linkCol.GetDateString(dataRow);
    }
    return "";
  }

  public GetDialogValueDataForColumn(
    col: GridColumnConfiguration,
    dataRow: any,
  ): string {
    const linkCol = col as GetDialogDataForColumnConfiguration;
    if (linkCol) {
      return linkCol.GetDialogString(dataRow);
    }
    return "";
  }

  public GetHyperLinkEnabled(
    col: GridColumnConfiguration,
    dataRow: any,
  ): boolean {
    const linkCol = col as GridHyperlinkColumnConfiguration;
    if (linkCol) {
      return linkCol.GetLinkIsEnabled(dataRow);
    }
    return true;
  }

  public GetHyperLinkAsHref(
    col: GridColumnConfiguration,
    dataRow: any,
  ): boolean {
    const linkCol = col as GridHyperlinkColumnConfiguration;
    if (linkCol) {
      return !linkCol.GetClickAvailable(dataRow);
    }
    return true;
  }

  public HyperLinkClickHandler(col: GridColumnConfiguration, dataRow: any) {
    const linkCol = col as GridHyperlinkColumnConfiguration;
    if (linkCol) {
      linkCol.RunClickCommand(dataRow);
    }
  }

  public GetLinkButtonClassForCell(
    col: GridColumnConfiguration,
    dataRow: any,
  ): string {
    const linkCol = col as GridLinkButtonColumnConfiguration;
    if (linkCol && linkCol.Icon && linkCol.Icon !== "") {
      return `fa ${linkCol.Icon}`;
    }

    return "";
  }

  public GetTooltipForCell(col: GridColumnConfiguration, dataRow: any): string {
    if (col.Tooltip) {
      return col.Tooltip(dataRow);
    }
    return "";
  }

  public IsItemDisabled(col: GridColumnConfiguration, dataRow: any): boolean {
    if (col.GetIsDisabled) {
      return col.GetIsDisabled(dataRow);
    }
    return false;
  }

  public rncheckboxclick(
    $event,
    col: GridCheckLimitColumnConfiguration,
    rowData: any,
  ) {
    this.configuration.RnCheckboxClick($event, col, rowData);
  }
  public RnCheckboxChanged(
    $event,
    col: GridCheckLimitColumnConfiguration,
    dataRow: any,
  ): void {
    this.configuration.RnCheckboxChange($event, col, dataRow);
  }
  public rnSelectAllChanged($event, col: GridCheckLimitColumnConfiguration) {
    this.configuration.RnSelectAll = !this.configuration.RnSelectAll;
    if (col.SelectAllChange) {
      col.SelectAllChange($event, this.configuration.RnSelectAll);
    }
  }
  public rnSelectAllDisabled(col: GridCheckLimitColumnConfiguration): boolean {
    if (col.selectAllDisabled) {
      return col?.selectAllDisabled;
    }
    return false;
  }
  public rnSelectAllTooltip(col: GridCheckLimitColumnConfiguration): string {
    if (col.SelectAllTooltip) {
      return col.SelectAllTooltip();
    }
    return "Select All";
  }

  public rnSelectAllVisible(col: GridCheckLimitColumnConfiguration): boolean {
    return col.SelectAllAvailable;
  }

  public GetActionsForColumn(
    col: GridActionButtonColumnConfiguration,
    rowData: any,
  ): Array<GridActions> {
    const actions = col.buttonConfig.find((b) => b.property == rowData);
    if (!actions) {
      return new Array<GridActions>();
    } else {
      return actions.buttonsText;
    }
  }

  public GetPredicateValue(col: GridColumnConfiguration, rowData: any) {
    const linkCol = col as GridActionButtonColumnConfiguration;
    return linkCol.predicate(rowData);
  }

  public actionClickHandler(value: any, type: string) {
    this.actionButtonClicked.emit({ value: value, type: type });
  }

  public headerCountClass(): string {
    return this.configuration &&
      this.configuration.HeaderClass &&
      this.configuration.HeaderClass !== ""
      ? this.configuration.HeaderClass
      : "default-header-count";
  }

  public firstRowNumDisplayed(): number {
    return this.configuration?.CurrentFirstRowNumber;
  }

  public lastRowNumDisplayed(): number {
    return (
      this.configuration?.CurrentFirstRowNumber +
      this.configuration.GridData?.length -
      1
    );
  }

  pageSize = 10;
  public pageSizeChanged() {
    this.navToPage(0);
  }

  public navToLastPage() {
    this.navToPage(this.getLastPageNumber());
  }
  public navToPage(pageNumber: number) {
    const data = {
      first: pageNumber * this.pageSize,
      rows: Number(this.pageSize),
      sortField: this.configuration.SortField,
      sortOrder: this.configuration.SortOrder,
    };
    // reset check item based on change
    this.selection = [];
    this.setSelection([]);
    this.configuration.RnSelectAll = false;

    this.configuration.lazyLoadData(data);
    this.paginatorData = this.getPaginatorData();
  }

  private getPaginatorData(): PaginationData[] {
    const start = 1;
    let max;
    const lastPage = this.getLastPageNumber() + 1;
    if (lastPage < 8) {
      return this.getPaginatorDataForPages(start, lastPage);
    }

    const endFirstRange = this.currentPageNum === 3 ? 4 : 3;
    let result: PaginationData[];
    result = this.getPaginatorDataForPages(1, endFirstRange);

    const elipsisItem = new PaginationData();
    elipsisItem.pageLabel = "...";
    elipsisItem.pageNumber = -1;
    result.push(elipsisItem);

    if (this.currentPageNum > 3 && this.currentPageNum < lastPage - 4) {
      const currPageItem = new PaginationData();
      currPageItem.pageLabel = (this.currentPageNum + 1).toString();
      currPageItem.pageNumber = this.currentPageNum;
      result.push(currPageItem);
      result.push(elipsisItem);
    }

    const startLastRange =
      this.currentPageNum === lastPage - 4 ? lastPage - 3 : lastPage - 2;
    result = result.concat(
      this.getPaginatorDataForPages(startLastRange, lastPage),
    );

    return result;
  }

  private getPaginatorDataForPages(
    start: number,
    end: number,
  ): PaginationData[] {
    const result = [];
    for (let i = start; i <= end; i++) {
      const item = new PaginationData();
      item.pageLabel = i.toString();
      item.pageNumber = i - 1;
      result.push(item);
    }
    return result;
  }

  public getLastPageNumber(): number {
    let lastPage =
      Math.floor(this.configuration.TotalRecords / this.pageSize) - 1;
    const leftOver = this.configuration.TotalRecords % this.pageSize;
    if (leftOver > 0) {
      lastPage++;
    }
    return lastPage;
  }

  public showPaginatorOverlay(): boolean {
    return this.configuration.Loading;
  }

  public OverlayText(): string {
    return this.configuration?.LoadingText &&
      this.configuration?.LoadingText !== ""
      ? this.configuration.LoadingText
      : "Loading...";
  }

  public currentPageNum: number;

  public getDefaultValue(rowData, col): number {
    const quantityCol = col as GridQuantityColumnConfiguration;
    if (quantityCol) {
      return quantityCol.getValue(rowData);
    }
    return 0;
  }

  public quantityChangeHandler($event, rowData, col): void {
    const quantityCol = col as GridQuantityColumnConfiguration;
    if (quantityCol) {
      quantityCol.onQuantityChanged(rowData, $event);
    }
  }

  public quantityEnteredHandler($event, rowData, col): void {
    const quantityCol = col as GridQuantityColumnConfiguration;
    if (quantityCol) {
      quantityCol.onEdit(rowData, $event);
    }
  }
}
