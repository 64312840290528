<div class="checkout-table-header">
  <div>Item</div>
  <div>Monthly Cost</div>
</div>
<div class="qbo-table-container">
  @for (item of dataSource; track item) {
    <div class="package-row" [ngClass]="{ 'last-row': $last }">
      <div>{{ item.PackageName }}</div>
      <div>${{ item.NextBillPrice | number: "1.2-2" }}</div>
    </div>
  }
  @if (payrollText) {
    <div class="disp-added-div">
      {{ payrollText }}
      <p class="float-right font-italic">Included with Payroll</p>
    </div>
  }
</div>
