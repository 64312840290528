import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-azure-credentials',
  templateUrl: './azure-credentials.component.html',
  styleUrl: './azure-credentials.component.scss'
})
export class AzureCredentialsComponent implements OnInit {
  entraUPN: string;

  constructor(private mdDialogRef: MatDialogRef<AzureCredentialsComponent>) {

  }

  ngOnInit() {

  }

  copyUPNToClipboard() {
    navigator.clipboard.writeText(this.entraUPN);
  }

  hideUPNModal() {
    this.mdDialogRef.close();
  }

}
