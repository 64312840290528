import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { UserLoginState } from "../../../../core/models/user.login.state";
import { QuantityCounts } from "../../../../feature-modules/welcomewizard/quantity-counts";
import { CartItem } from "../../../services/cart/cart-item";
import { CartService } from "../../../services/cart/cart.service";
import { ConstantsService } from "../../../services/constants/constants.service";
import { RnDialogService } from "../../../services/dialog/rndialog.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { LookupStoreService } from "../../../services/lookup-store/lookup-store.service";
import { OrganizationLogicService } from "../../../services/organization-logic/organization-logic.service";
import {
  RnOrganizationConfigurationVM,
  RnOrganizationsProfileVM,
  RnOrganizationsVM,
  RnOrganizationWLPContactInformationVM,
} from "../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { BillingFormData } from "../billing-form/billing-form.data";
import { PackageCardData } from "../package-card/package-card-data";
import { AddonGroup } from "./addon-group";

class disabledInfo {
  isDisabled: boolean;
  disabledText: string;
  disablingPackageID: number;
  disablingPackageName: string;
}

@Component({
  selector: "app-manage-addons",
  templateUrl: "./manage-addons.component.html",
  styleUrls: ["./manage-addons.component.scss"],
})
export class ManageAddonsComponent implements OnDestroy, OnChanges {
  @Input() set SelectedPackage(selectedPackage: PackageCardData) {
    this.selectedPackage = selectedPackage;
  }
  @Input() addonsSelectForm: FormGroup;
  @Input() OrganizationID: number;
  @Input() IsAccountOwner = false;
  @Input() set AddOnList(addOns: PackageCardData[]) {
    this.addOns = addOns;
    this.setUpAddOnGroups();
  }
  @Input() public orgProfile: RnOrganizationsProfileVM;
  @Input() public orgConfig: RnOrganizationConfigurationVM;
  @Input() public orgWlpContactInfo: RnOrganizationWLPContactInformationVM;
  @Input() public AssignedAddOns: Array<string> | null;
  @Input() hideTitles = false;
  @Output() OwnerAddonAction = new EventEmitter<QuantityCounts[]>();
  @Output() PackageChanged = new EventEmitter<boolean>();

  purchasableAddOnGroups: AddonGroup[];
  nonPurchasableAddOnGroups: AddonGroup[];
  infoText: string;
  ownerAddonActions: QuantityCounts[] = [];
  private passedOnChanges = false;

  constructor(
    private cart: CartService,
    private loggedinInfoService: LoggedInInfoService,
    private orgLogicService: OrganizationLogicService,
    private lookupStoreService: LookupStoreService,
    private rnDialogService: RnDialogService,
    private toastService: RnToastService,
    private constantsService: ConstantsService,
  ) {
    this.subscriptions.push(
      this.loggedinInfoService.LoggedInUserOrg.subscribe((o) => {
        this.loggedInUserOrg = o;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    let orgChanged = false;
    for (const propName in changes) {
      if (propName === "orgProfile") {
        this.orgProfile = changes[propName]
          .currentValue as RnOrganizationsProfileVM;
        orgChanged = true;
      }
    }
    if (
      !this.passedOnChanges &&
      this.addonsSelectForm &&
      this.OrganizationID &&
      this.orgProfile &&
      this.orgConfig &&
      this.orgWlpContactInfo
    ) {
      this.PRCHDROPPK = this.loggedinInfoService.loggedInUserHasRights([
        "PRCHDROPPK",
      ]);
      this.PMTMTDCREATE = this.loggedinInfoService.loggedInUserHasRights([
        "PMTMTDCREATE",
      ]);
      this.UPDTBILING = this.loggedinInfoService.loggedInUserHasRights([
        "UPDTBILING",
      ]);
      this.loggedInUser = this.loggedinInfoService.GetLoggedInUser();
      if (!this.loggedInUser) {
        return;
      }

      this.setupPurchaseFlags();
      if (this.orgProfile) {
        this.setInfoText();
      }
      this.canPurchase = this.orgLogicService.CanPurchaseDropPackages(
        this.PRCHDROPPK,
        this.loggedInUser?.IsInternal(),
        this.orgProfile,
        this.loggedinInfoService.GetLoggedInUserOrg(),
      );
      this.setupPurchaseFlags();
      this.passedOnChanges = true;
    } else if (
      orgChanged &&
      this.addonsSelectForm &&
      this.OrganizationID &&
      this.orgProfile &&
      this.orgConfig &&
      this.orgWlpContactInfo
    ) {
      this.setupPurchaseFlags();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  private selectedPackage: PackageCardData;
  private addOns: PackageCardData[];
  private setUpAddOnGroups(): void {
    this.purchasableAddOnGroups = [];
    this.nonPurchasableAddOnGroups = [];
    this.selectedAddOns = [];

    this.addOns = this.addOns.filter((a) => {
      return a.PackageType !== "One Time Charge";
    });

    if (this.addOns) {
      const purchasableGroupedAddOns = this.addOns
        .filter((a) => {
          return a.CatalogVisibility !== "Unpurchasable";
        })
        .sort((a, b) => {
          if (a.CatalogSortOrder !== b.CatalogSortOrder) {
            return a.CatalogSortOrder > b.CatalogSortOrder ? 1 : -1;
          }

          return a.SortOrder > b.SortOrder ? 1 : -1;
        });

      const nonPurchasableGroupdAddOns = this.addOns
        .filter((a) => {
          return a.CatalogVisibility === "Unpurchasable";
        })
        .sort((a, b) => {
          if (a.CatalogSortOrder !== b.CatalogSortOrder) {
            return a.CatalogSortOrder > b.CatalogSortOrder ? 1 : -1;
          }

          return a.SortOrder > b.SortOrder ? 1 : -1;
        });

      let group: AddonGroup = null;
      purchasableGroupedAddOns.forEach((a) => {
        const grpID = a.CatalogCategoryID;
        const grpName = a.CatalogCategoryDesc;
        let grpDbName = a.CatalogCategoryName;
        const grpDescription = a.CatalogCategoryHtmlDesc;

        // Okay, I have to intercept a couple of the OneSpace package categories that were only added
        // because of dumb stuff in the NTTF flow to make them basically the regular addon category here,
        // but the category info has to stay in the addon object itself because of bs and foolishness
        // with the "you can't assign this because blah blah blah" tooltip being displayed

        if (
          a.CatalogCategoryName === "OneSpaceDesktop" ||
          a.CatalogCategoryName === "OneSpaceOffice"
        ) {
          grpDbName = "AdditionalAddons";
        }

        if (!group || group.GroupDbName !== grpDbName) {
          group = new AddonGroup();
          group.GroupID = grpID;
          group.GroupName = grpName;
          group.GroupDbName = grpDbName;
          group.GroupDescription = grpDescription;
          group.Addons = [];
          this.purchasableAddOnGroups.push(group);
        }
        group.Addons.push(a);
      });

      // sorting the groups after getting everything together just in case
      this.purchasableAddOnGroups.forEach((g) =>
        g.Addons.sort((a, b) => {
          return a.SortOrder > b.SortOrder ? 1 : -1;
        }),
      );

      const intuitUpgrdGroup = this.purchasableAddOnGroups.filter((g) => {
        return g.GroupDbName === "IntuitAddons";
      });
      if (intuitUpgrdGroup.length > 0 && this.intuitAddOnInfo !== "") {
        const infoOnlyAddOn = new PackageCardData();
        infoOnlyAddOn.InfoOnly = true;
        infoOnlyAddOn.PackageDescHtml = this.intuitAddOnInfo;
        infoOnlyAddOn.RequiredPackagesIds = [];
        infoOnlyAddOn.RequiredPackagesNames = [];
        infoOnlyAddOn.ExcludingPackageIds = [];
        infoOnlyAddOn.ExcludingPackageNames = [];
        intuitUpgrdGroup[0].Addons.push(infoOnlyAddOn);
      }

      group = null;
      nonPurchasableGroupdAddOns.forEach((a) => {
        if (!group || group.GroupID !== a.CatalogCategoryID) {
          group = new AddonGroup();
          group.GroupID = a.CatalogCategoryID;
          group.GroupName = a.CatalogCategoryDesc;
          group.GroupDbName = a.CatalogCategoryName;
          group.GroupDescription = a.CatalogCategoryHtmlDesc;
          group.Addons = [];
          this.nonPurchasableAddOnGroups.push(group);
        }
        group.Addons.push(a);
      });
    }

    if (this.AssignedAddOns != null && this.AssignedAddOns != undefined) {
      this.AssignedAddOns.forEach((assignedAddOn) => {
        const filteredAddOn = this.addOns.filter(
          (addOn) => addOn.PackageName == assignedAddOn,
        );
        if (filteredAddOn && filteredAddOn.length > 0) {
          filteredAddOn[0].AddOnAdded = true;
        }
      });
    }

    this.selectedAddOns = this.addOns?.filter((a) => {
      return a.AddOnAdded;
    });
    this.f.selectedAddons.setValue(this.selectedAddOns);
  }

  packageCardAdd($event) {
    const pkg = $event as PackageCardData;

    if (this.IsAccountOwner) {
      // make sure this isn't an already assigned addon being re-selected
      if (!this.ownerAddonActions.find((p) => p.PackageID == pkg.PackageID)) {
        this.ownerAddonActions.push({
          PackageID: pkg.PackageID,
          Quantity: 1,
        });
      }
      this.OwnerAddonAction.emit(this.ownerAddonActions);
    }

    if (
      pkg.CatalogVisibility !== "Unpurchasable" &&
      pkg.CurrentQuantity === pkg.CurrentUsed &&
      !this.loggedInUserOrg.HasDirectPaymentOnFile
    ) {
      const data = new BillingFormData();
      data.organization = this.loggedInUserOrg;

      const billingFormDialog = this.rnDialogService.BillingFormDialog(data);
      billingFormDialog.beforeClosed().subscribe((s) => {
        if (s) {
          this.completeAdd(pkg);
        } else {
          this.toastService.showError(
            this.constantsService.cancelCreateBillingInfoAddOnsToast,
          );
        }
      });

      return;
    }

    this.completeAdd(pkg);
  }

  private completeAdd(pkg: PackageCardData) {
    if (pkg.CurrentUsed === pkg.CurrentQuantity) {
      pkg.BuyNew = true;
      const cartItem = new CartItem();
      cartItem.Name = pkg.PackageName;
      cartItem.Quantity = 1;
      cartItem.DiscountPercent = pkg.DiscountPercent;
      cartItem.Price = pkg.ListPrice;
      cartItem.NextBillPrice = pkg.NextBillPrice;
      cartItem.PackageID = pkg.PackageID;
      cartItem.ItemType = "Add-On";
      this.cart.addToCart(cartItem);
      pkg.CurrentQuantity++;
    }

    pkg.AddOnAdded = true;
    this.selectedAddOns.push(pkg);
    pkg.CurrentUsed++;
    if (this.f) {
      this.f.selectedAddons.setValue(this.selectedAddOns);
    }
    this.PackageChanged.emit(true);
  }

  packageCardRemove($event) {
    const pkg = $event as PackageCardData;

    if (this.IsAccountOwner) {
      if (this.AssignedAddOns.find((p) => p == pkg.PackageName)) {
        this.ownerAddonActions.push({
          PackageID: pkg.PackageID,
          Quantity: -1,
        });
      } else {
        const foundIndex = this.ownerAddonActions.findIndex(
          (p) => p.PackageID == pkg.PackageID,
        );
        if (foundIndex >= 0) {
          this.ownerAddonActions.splice(foundIndex, 1);
        }
      }
      this.OwnerAddonAction.emit(this.ownerAddonActions);
    }

    pkg.AddOnAdded = false;
    pkg.CurrentUsed--;
    if (pkg.BuyNew) {
      pkg.BuyNew = false;
      this.cart.removeFromCart(pkg);
      pkg.CurrentQuantity--;
    }
    pkg.AddOnAdded = false;
    const rmvIdx = this.selectedAddOns.indexOf(pkg);
    this.selectedAddOns.splice(rmvIdx, 1);

    for (let i = 0; i < this.addOns.length; i++) {
      const tempAddOn = this.addOns[i];
      const testIdx = this.selectedAddOns.findIndex(
        (a) => tempAddOn.PackageID === a.PackageID,
      );
      if (testIdx >= 0) {
        const rmvAddOn = this.selectedAddOns[testIdx];
        if (!this.doesAddOnHaveRequiredItems(rmvAddOn)) {
          this.packageCardRemove(rmvAddOn);
        }
      }
    }

    if (this.f) {
      this.f.selectedAddons.setValue(this.selectedAddOns);
    }
    this.PackageChanged.emit(true);
  }

  addonIsDisabled(addOn: PackageCardData): boolean {
    let result = false;
    if (
      addOn.CatalogVisibility === "Unpurchasable" &&
      addOn.CurrentQuantity === addOn.CurrentUsed &&
      !addOn.BuyNew
    ) {
      return true;
    }

    if (
      addOn.RequiredPackagesIds.length > 0 &&
      addOn.RequiredPackagesIds.filter((p) => {
        return p === this.selectedPackage.PackageID;
      }).length === 0
    ) {
      result = !this.doesAddOnHaveRequiredItems(addOn);
    }

    this.selectedAddOns.forEach((a) => {
      if (
        a.ExcludingPackageIds.filter((p) => {
          return p === addOn.PackageID;
        }).length > 0
      ) {
        result = true;
      }
    });

    if (
      this.selectedPackage &&
      this.selectedPackage.PackageName != "No Package" &&
      this.selectedPackage.ExcludingPackageIds.filter(
        (x) => x == addOn.PackageID,
      ).length > 0
    ) {
      result = true;
    }

    if (!result) {
      if (
        addOn.CatalogVisibility !== "Unpurchasable" &&
        addOn.CurrentQuantity === addOn.CurrentUsed
      ) {
        if (!this.canPurchase && !this.canPurchaseNeedsPOF) {
          result = true;
        }
      }
    }
    return result;
  }

  addonTooltip(addOn: PackageCardData): string {
    if (
      !this.addonIsDisabled(addOn) ||
      (this.orgProfile && this.orgProfile.Status == "AddOnSuspended")
    ) {
      return "";
    }

    const disabledInfo = this.getDisabledInfo(addOn);
    if (disabledInfo.isDisabled) {
      return disabledInfo.disabledText;
    }

    if (
      addOn.CatalogVisibility !== "Unpurchasable" &&
      this.orgProfile?.Status === "WriteOff"
    ) {
      return "Add-ons are not available because this account is in Write-Off status.";
    }
    if (
      addOn.CatalogVisibility !== "Unpurchasable" &&
      addOn.CurrentQuantity === addOn.CurrentUsed &&
      !this.canPurchase &&
      !this.canPurchaseNeedsPOF &&
      !addOn.AddOnAdded
    ) {
      return '<span data-testid="tooltipMsg">There are no remaining add-ons to assign. Contact the Account Owner to have them add more to their account.</span>';
    }
    if (addOn.RequiredPackagesIds.indexOf(this.selectedPackage.PackageID) < 0) {
      if (
        addOn.CatalogCategoryName === "OneSpaceDesktop" ||
        addOn.CatalogCategoryName === "OneSpaceOffice"
      ) {
        return "<span data-testid='tooltipMsg'>You will need to select OneSpace to select this add-on.</span>";
      } else if (addOn.CatalogVisibility !== "Unpurchasable") {
        return "<span data-testid='tooltipMsg'>You will need to select either a package or add-on that includes QuickBooks to select this add-on.</span>";
      } else if (addOn.CatalogVisibility === "Unpurchasable") {
        return "<p class='tooltip_p_400w'>You will need to select an applicable base package to select this premium add-on.</p>";
      }
    }
    if (
      addOn.CatalogVisibility === "Unpurchasable" &&
      addOn.CurrentQuantity === addOn.CurrentUsed &&
      !addOn.BuyNew
    ) {
      return "<p class='tooltip_p_400w'>There are no remaining add-ons to assign.</p>";
    }
  }

  private selectedAddOns: PackageCardData[] = [];
  private subscriptions: Subscription[] = [];
  private intuitAddOnInfo = "";
  private PRCHDROPPK: boolean;
  private PMTMTDCREATE: boolean;
  private UPDTBILING: boolean;
  private canPurchase: boolean;
  private loggedInUser: UserLoginState;
  private loggedInUserOrg: RnOrganizationsVM;
  private canPurchaseNeedsPOF: boolean;

  private setInfoText(): void {
    if (this.orgProfile.CustomerType === "Intuit_PCG") {
      this.subscriptions.push(
        this.lookupStoreService
          .GetProseriesLacerteSalesnumber()
          .subscribe((s) => {
            this.infoText = `To purchase additional seats, please contact ${s}.`;
          }),
      );
    } else if (this.orgProfile.CustomerType === "Intuit") {
      this.subscriptions.push(
        this.lookupStoreService.GetIntuitSalesNumber().subscribe((s) => {
          this.infoText = `Please contact Intuit Sales at ${s} to purchase additional hosting packages.`;
        }),
      );
      this.subscriptions.push(
        this.lookupStoreService.GetIntuitAddonsInfo().subscribe((s) => {
          this.intuitAddOnInfo = s;
        }),
      );
    } else {
      this.infoText = `To purchase additional seats, please contact ${this.orgWlpContactInfo.SalesPhone}.`;
    }
  }

  getDisabledInfo(addOn: PackageCardData): disabledInfo {
    const result = new disabledInfo();

    let excludingAddOns = "";

    this.selectedAddOns.forEach((a) => {
      if (
        a.ExcludingPackageIds.filter((p) => {
          return p === addOn.PackageID;
        }).length > 0
      ) {
        if (excludingAddOns === "") {
          excludingAddOns = a.PackageName;
        } else {
          excludingAddOns += `, ${a.PackageName}`;
        }
      }
    });

    if (excludingAddOns) {
      result.isDisabled = true;
      result.disabledText =
        "<span data-testid='tooltipMsg'>" +
        `This add-on is not available when the ${excludingAddOns} add-on is selected` +
        "</span>";
    }

    if (
      this.selectedPackage &&
      this.selectedPackage.PackageName != "No Package" &&
      this.selectedPackage.ExcludingPackageIds.filter(
        (x) => x == addOn.PackageID,
      ).length > 0
    ) {
      result.isDisabled = true;
      result.disabledText =
        "<span data-testid='tooltipMsg'>This add-on is not available when " +
        this.selectedPackage.PackageName +
        " is assigned</span>";
    }

    return result;
  }

  private setupPurchaseFlags(): void {
    if (this.orgProfile && this.orgConfig) {
      this.canPurchase = this.orgLogicService.CanPurchaseDropPackages(
        this.PRCHDROPPK,
        this.loggedInUser.IsInternal(),
        this.orgProfile,
        this.loggedInUserOrg,
      );
      this.canPurchaseNeedsPOF =
        this.orgLogicService.CanPurchasePackagesNeedPOF(
          this.PRCHDROPPK,
          this.UPDTBILING,
          this.loggedInUser.IsInternal(),
          this.orgProfile,
          this.orgConfig,
          this.loggedInUserOrg,
          this.loggedInUser.UserType,
        );
    }
  }

  private doesAddOnHaveRequiredItems(addOn: PackageCardData): boolean {
    // first check any requirements or selectedPackage is a required package
    if (
      addOn.RequiredPackagesIds.length === 0 ||
      addOn.RequiredPackagesIds.indexOf(this.selectedPackage.PackageID) >= 0
    ) {
      return true;
    }

    // check if any addons selected are required
    let requiredAddOnSelected = false;
    this.selectedAddOns.forEach((a) => {
      if (addOn.RequiredPackagesIds.indexOf(a.PackageID) >= 0) {
        requiredAddOnSelected = true;
      }
    });

    return requiredAddOnSelected;
  }

  get f() {
    return this.addonsSelectForm.controls;
  }
}
