import { Injectable } from "@angular/core";
import { PackageCardData } from "../../components/rn-business-components/package-card/package-card-data";
import { BehaviorSubject, Observable } from "rxjs";
import { PackageUpdateEventData } from "./package-update-event-data.model";

@Injectable({
  providedIn: "root",
})
export class PackagesService {
  private packageUpdatSubject = new BehaviorSubject<PackageUpdateEventData>(
    null,
  );

  public isApplicableDeployQuickBooks(
    selectedPackage: PackageCardData,
    licenses: any,
    addOns: PackageCardData[],
  ): boolean {
    const addOnsRequiringLicense = addOns.filter(
      (x) => x.UsesLicenses === true,
    );
    const selectionsRequireLicense =
      selectedPackage.UsesLicenses || addOnsRequiringLicense.length > 0;
    const assignableLicenses = licenses.filter(
      (x) => x.IsAutoAssignable === true,
    );
    const hasAssignableLicenses =
      assignableLicenses && assignableLicenses.length > 0;
    if (hasAssignableLicenses && selectionsRequireLicense) {
      return true;
    }
    return false;
  }

  updatePackageEvent(event: PackageUpdateEventData) {
    this.packageUpdatSubject.next(event);
  }

  onUpdatePackageEvent(): Observable<PackageUpdateEventData> {
    return this.packageUpdatSubject.asObservable();
  }

  /**
   * Get a map of exclusive product SKUs (per purchase), ie. only 1 of the group can be selected.
   * Hard-coded for now but could be moved to COSMOS if we need to support more such configurations.
   * Key value is the tooltip message to display when the user tries to select more than one SKU from the group.
   */
  getExclusiveSkuMap(): Observable<Map<string, string[]>> {
    // Exclusive product SKUs, mapping a 'friendly name' to list of SKU values which are exclusive,
    // ie. only one can be selected
    const exclusiveSkuMap = new Map<string, string[]>();
    const wispTooltip =
      "In order to purchase this product please remove any Written Information Security Plan (WISP) products already added to your cart.";
    exclusiveSkuMap.set(wispTooltip, ["RW-WISP", "RW-IRP"]);
    return new Observable((observer) => {
      observer.next(exclusiveSkuMap);
      observer.complete();
    });
  }
}
