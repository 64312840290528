import { Component, Input, Output, EventEmitter } from "@angular/core";
import { SortData } from "./sort-data.model";

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrl: "./paginator.component.scss",
})
export class PaginatorComponent {
  private _gridData: any[] = [];
  private _sortData: SortData = new SortData("", 0);
  currentPageNum: number;
  pages: number[];

  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() pageSize: number = 10;
  @Output() pageChanged = new EventEmitter<any[]>();
  @Input() set gridData(data: any[]) {
    if (data === undefined || data === null) {
      data = [];
    }
    this._gridData = data;
    this.updatePagination();
  }

  @Input() set sortData(data: SortData) {
    if (!data) {
      throw new Error("Invalid sort data passed to paginator");
    }
    if (
      this._sortData.field === data.field &&
      this._sortData.order === data.order
    ) {
      return;
    }
    this._sortData = data;
    this.sort();
  }

  get gridData(): any[] {
    return this._gridData;
  }

  private sort(): void {
    if (!this._sortData.field) {
      return;
    }
    this._gridData = this._gridData.sort((a, b) => {
      const valueA = a[this._sortData.field];
      const valueB = b[this._sortData.field];
      if (valueA < valueB) {
        return this._sortData.order === 1 ? -1 : 1;
      }
      if (valueA > valueB) {
        return this._sortData.order === 1 ? 1 : -1;
      }
      return 0;
    });
    this.updatePagination();
  }

  updatePagination() {
    const pages = Math.ceil(this._gridData.length / this.pageSize);
    this.currentPageNum = 1;
    this.pages = Array.from({ length: pages }, (_, i) => i + 1);
    this.pageChanged.emit(this._gridData.slice(0, this.pageSize));
  }

  pageSizeChanged() {
    this.updatePagination();
  }

  getLastPageNumber() {
    return this.pages?.length;
  }

  navToPage(pageNumber: number) {
    this.currentPageNum = pageNumber;
    const sliceStart = (pageNumber - 1) * this.pageSize;
    const sliceEnd = pageNumber * this.pageSize;
    const pageData = this._gridData.slice(sliceStart, sliceEnd);
    this.pageChanged.emit(pageData);
  }

  getItemRangeText(): string {
    const start = (this.currentPageNum - 1) * this.pageSize + 1;
    const end = Math.min(
      this.currentPageNum * this.pageSize,
      this._gridData.length,
    );
    return `${start} - ${end} of ${this._gridData.length}`;
  }
}
