<div class="overlay-container">
  <app-loader
    *ngIf="showProcessingOverlay && !parentIsLoading"
    text="Processing..."
  ></app-loader>
  <app-loader
    *ngIf="isLoading && !parentIsLoading"
    text="Loading inventory resources..."
  ></app-loader>
  <div class="medium packageCatGroup" *ngIf="!isLoading">
    <div
      data-testid="packageAddonSection"
      class="medium"
      *ngFor="let group of categoryGroups; let i = index"
    >
      <mat-card appearance="outlined" class="mat-elevation-z0">
        <mat-panel>
          <mat-panel-header>
            <mat-panel-title>
              <div
                class="panel-heading"
                [ngClass]="{
                  packagePanelHeading: !(group | rnPackageGroup: 'isaddon'),
                  addonPanelHeading: (group | rnPackageGroup: 'isaddon')
                }"
              >
                <p data-testid="packagePanelTitle" class="packagePanelTitle">
                  Select {{ group.Name }}
                </p>
              </div>
            </mat-panel-title>
          </mat-panel-header>
          <hr />
          <div
            class="panelCatDescription"
            *ngIf="group | rnPackageGroup: 'ispackage'"
          >
            <span data-testid="packageText"
              >Select the package that fits you and your team and how you want
              to work within the Rightworks cloud environment.<br /><br />If you
              need further assistance with a selection or would like to learn
              about volume discounts, contact us at
              <span data-testid="pkgSalesPhone" class="ws-nowrap">{{
                sales_phone
              }}</span
              >.</span
            >
          </div>
          <div
            class="panelCatDescription"
            *ngIf="group | rnPackageGroup: 'isaddon'"
          >
            <span data-testid="addonText"
              >We provide additional applications which can be used within the
              Rightworks cloud environment.<br /><br />If you need further
              assistance with a selection contact us at
              <span data-testid="addonSalesNumber" class="ws-nowrap">{{
                sales_phone
              }}</span
              >.</span
            >
          </div>
          <div
            class="panelCatDescription"
            *ngIf="group | rnPackageGroup: 'isintuitupgrade'"
          >
            <p data-testid="intuitText">
              Rightworks offers upgrades for Intuit customers that need access
              to more applications and services.<br /><br />These upgrades do
              not replace the hosting service you purchased through Intuit –
              they augment it. Upgrades require you to establish a billing
              relationship with Rightworks.
            </p>
            <br />
            <b data-testid="intuitClassicPackageText"
              >{{ intuitQbesOrgBasePackage.PackageName }} Package:
            </b>
            <span data-testid="intuitSeats"
              >{{ intuitQbesOrgBasePackage.CurrentQuantity }} Seats
            </span>
            <br />
            <span data-testid="QBESMsg">Note: {{ intuitQbesMaxHint }}</span>
          </div>
          <div class="package-cards">
            <app-package-card
              [attr.data-testid]="Package.PackageName + '_card'"
              class="package-card noHover"
              *ngFor="let Package of group.Values"
              [AddQtyControlsDisabled]="disableAddQtyChange"
              [QtyControlsDisabled]="disableQtyChange"
              [Disabled]="disabledByExclusivity(Package)"
              [DisabledToolTip]="getDisabledTooltip(Package)"
              MaxAllowed="Package.MaxItemsAllowed"
              [Package]="Package"
              [InternalExclusiveTooltip]="getInternalExclusiveTooltip(Package)"
              (UpdateChanges)="updateChanges($event)"
              [MaxUsedMessage]="intuitQbesAddonsMaxHint"
            ></app-package-card>
          </div> </mat-panel
      ></mat-card>
    </div>
  </div>
  <mat-card appearance="outlined" class="mat-elevation-z0 changeDetails">
    <div *ngIf="!isLoading">
      <div class="sectionTitle">Change Details</div>
      <div data-testid="changesText" class="p-b-8 changesText">
        These changes will be applied to your current estimated bill. You can
        review your current detailed bill by clicking on the Billing tab.
      </div>
      <rn-grid [Configuration]="ChangeTableConfiguration"></rn-grid>
      <div class="totalsLightBlue" *ngIf="cart.length > 0">
        <span data-testid="totalCharges" class="columnTitle"
          >Total Charges:<span class="columnData"
            >&nbsp;{{ totals | currency }}</span
          ></span
        >
        <span data-testid="currentEstimatedBill" class="columnTitle"
          >Current Estimated Bill:<span class="columnData"
            >&nbsp;{{ currentMonthlyBill | currency }}</span
          ></span
        >
        <span
          class="fw-bold"
          data-testid="futureEstimatedTotal"
          class="columnTitle"
          >Future Estimated Total:<span class="columnData"
            >&nbsp;{{
              totals
                | rnPackageInventory
                  : "futureesttotal"
                  : allPackages
                  : discountedBalance
                  : currentMonthlyBill
                  : validatedPromo
                | currency
            }}</span
          ></span
        >
        <span class="taxData">Plus tax where applicable</span>
      </div>
    </div>
    <div *ngIf="!this.isLoading">
      <div class="promoCodeWell" *ngIf="canShowPromoCodeEntry">
        <div class="pull-left">
          <label class="promo-label pull-left" for="promoTextbox"
            >Promo Code</label
          >
          <br />
          <div class="form-group disp-inl-flex">
            <input
              data-testid="promoInput"
              id="promoTextbox"
              maxlength="25"
              autocomplete="off"
              ng-focus="vm.error = false"
              ng-class="{ 'sharp-angles-error': vm.error }"
              type="text"
              [(ngModel)]="inputPromoCode"
              placeholder="Promo Code"
              required
              class="textbox-sharp-angles form-control"
              (input)="onPromoChange()"
            />
            <button
              [disabled]="!inputPromoCode"
              type="button"
              (click)="validatePromoCode()"
              class="primaryButton"
              (ngClass)="
                (!promoCodeIsValid && !promoCodeWaiting
                  ? 'blue-promo-apply-btn'
                  : 'blue-promo-apply-btn blue-promo-apply-btn-default-pointer')
              "
              data-testid="btnValidatePromoCode-ChangePackage"
            >
              <span *ngIf="!promoCodeIsValid && !promoCodeWaiting">APPLY</span>
              <span *ngIf="promoCodeWaiting"
                ><app-icon
                  source="rn-circle-notch"
                  imgClass="loading-spinner gray-icon "
                ></app-icon
              ></span>
              <span *ngIf="promoCodeIsValid"
                ><i class="fa-solid fa-check promo-apply-check" aria-hidden="true"></i
              ></span>
            </button>
          </div>
          <div class="red-help-block" *ngIf="promoCodeError">
            Promo Code {{ inputPromoCode }} is not valid or has expired.
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!this.isLoading" class="form-group rightAlignButtons">
      <app-button
        id="btnCancel"
        label="CANCEL"
        noContentPadding="true"
        buttonStyle="addUserTeritary"
        (clickActionEmitter)="cancelPackageMix()"
      >
      </app-button>
      <app-button
        id="btnSave"
        tooltip="{{ buttonTooltip }}"
        tooltipsPosition="left"
        label="SAVE CHANGES"
        (clickActionEmitter)="savePackageMix()"
        noContentPadding="true"
        [disabled]="
          !(this.totals !== 0 && !this.disableQtyChange) ||
          qbesAddonsOutOfCompliance
        "
        buttonStyle="primary"
      >
      </app-button>
    </div>
  </mat-card>
</div>
