<div class="modal-header justify-content-between">
  <h3 class="modal-title">Azure credentials</h3>
  <rw-button [buttonclass]="'btn-close'"
             [arialabel]="'close azure credentials dialog'"
             [datapendoid]="'azure-credentials-modal-xbutton'"
             [datatestid]="'azure-credentials-modal-xbutton'"
             (clickButton)="hideUPNModal()">
  </rw-button>
</div>
<div class="modal-body">
  <p>Your Azure login is different from your Rightworks login. You will need to remember your password in order to access your Azure Account.</p>
  <br><p>
    <span class="azureEmailText">Azure email login:</span>  {{entraUPN}}
    <rw-button [tooltiptext]="'Copy to clipboard'"
               [arialabel]="'copy to clipboard button'"
               [datapendoid]="'azurecredentials-copytoclipboard-button'"
               [datatestid]="'azurecredentials-copytoclipboard-button'"
               [iconclass]="'fa-regular fa-clone'"
               (clickButton)="copyUPNToClipboard()"></rw-button>
  </p>
</div>
<div class="modal-footer">
  <rw-button [buttonclass]="'rw-btn-primary'"
             [arialabel]="'close azure credentials dialog'"
             [datapendoid]="'azure-credentials-modal-closebutton'"
             [datatestid]="'azure-credentials-modal-closebutton'"
             [text]="'CLOSE'"
             (clickButton)="hideUPNModal()">
  </rw-button>
</div>
