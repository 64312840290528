import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  RnUsersSearchVM,
  RnCommonSearch,
} from "../../../services/rnapi2-service/models/models";
import { GridCheckLimitColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-check-limit-column-configuration";
import { GridConfiguration } from "../../third-party-wrappers/grid/configuration/grid-configuration";
import { OrganizationService } from "src/app/shared/services/rnapi2-service/apis/organization.service";
import { Observable, Subject, Subscription } from "rxjs";
import { SearchControlConfiguration } from "../search-control/configuration/search-control-configuration";
import { RnsidebarService } from "src/app/shared/services/sidebar/rnsidebar.service";
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { RnGrantAccessSearchResultVM } from "src/app/shared/services/rnapi2-service/models/grantAccessSearchResultVM-d";
import { UserService } from "src/app/shared/services/rnapi2-service";
import { RnDriveMappingMultipleUser } from "src/app/shared/services/rnapi2-service/models/driveMappingMultipleUser-d";
import { AccessMgmtAssignData } from "src/app/feature-modules/organization/organization-details/organization-access-management/access-mgmt-assign-config";
import { RnGrantAccessSearchResultUnmappedVM } from "src/app/shared/services/rnapi2-service/models/grantAccessSearchResultUnmappedVM-d";
import { RnUnmappedUsersSearchSort } from "src/app/shared/services/rnapi2-service/models/unmappedUsersSearchSort-d";
import { RnMappedUserSearchVMSearchResultsVM } from "src/app/shared/services/rnapi2-service/models/mappedUserSearchVMSearchResultsVM-d";
import { RnGrantAccessSearchResultVMSearchResultsVM } from "src/app/shared/services/rnapi2-service/models/grantAccessSearchResultVMSearchResultsVM-d";
import { EventModel } from "src/app/core/models/event-model.model";
import { OverlayPanel } from "primeng/overlaypanel";
import { RnToastService } from "src/app/shared/services/toast/rntoast.service";
import { SortData } from "../paginator/sort-data.model";

@Component({
  selector: "app-access-management-assign",
  templateUrl: "./access-management-assign.component.html",
  styleUrls: ["./access-management-assign.component.scss"],
})
export class AccessManagementAssignComponent implements OnInit, OnDestroy {
  @Input() set data(eventsData: AccessMgmtAssignData) {
    if (eventsData) {
      this.userProfile = eventsData;
    }
  }
  @Output() componentLoaded = new EventEmitter<boolean>();
  @ViewChild("search") searchTextBox: ElementRef;
  @ViewChild("op") overlayPanel: OverlayPanel;

  displayedColumns = ["FullName", "Email", "OrganizationName", "remove"]; //, 'Action'
  searchText = "";
  orgNameFolderName: string;
  clearSearch: Subject<void> = new Subject<void>();
  organizationId: number;
  accessMgmtGridConfiguration: GridConfiguration = new GridConfiguration();
  userProfile: AccessMgmtAssignData;
  autoAssignInProgress = false;
  isEditing = false;
  isProcessing = false;
  searchControlConfiguration: SearchControlConfiguration;
  userGrantAccessBtnDisabled = true;
  selectionColumnConfig: GridCheckLimitColumnConfiguration;
  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();
  isUnassignUsersDisabled = true;
  selectedValues = [];
  filteredOptions: Observable<any[]>;
  usersToRemove = [];
  rnGrantAccessSearchResultUnmappedVM: RnGrantAccessSearchResultUnmappedVM[];
  rnMappedUserSearchVMSearchResultsVM: RnMappedUserSearchVMSearchResultsVM;
  rnGrantAccessSearchResultVMSearchResultsVM: RnGrantAccessSearchResultVMSearchResultsVM;
  rnGrantAccessSearchResultVM: RnGrantAccessSearchResultVM[];
  SelectedUsersForRemoval = [];
  datas = [];
  lstUser = [];
  selecedOrgID: number;
  isOrg = false;
  isLoading = false;
  public tooltip: string;
  private rnCommonSearch: RnCommonSearch = new RnCommonSearch();
  rnUnmappedUsersSearch = new RnUnmappedUsersSearchSort();
  rnUnmappedUsersSearchPost = new RnUnmappedUsersSearchSort();
  localParams: EventModel = new EventModel();
  tbData: any = [];
  dataSource: any = [];
  userPageData: any = [];
  userTableSortData: SortData = new SortData("", 0);

  constructor(
    private organizationService: OrganizationService,
    private rnsidebarService: RnsidebarService,
    private userService: UserService,
    private toastService: RnToastService
  ) {}

  ngOnInit(): void {
    this.searchControlConfiguration = new SearchControlConfiguration();
    this.searchControlConfiguration.EditFieldPlaceholderText =
      "Search for users or organizations";
    this.searchControlConfiguration.EditClass = "org-user-search-edit";
    this.searchControlConfiguration.ContainerClass =
      "col-xs-12 col-sm-12 col-md-12 col-lg-12";
    this.searchControlConfiguration.ShowMagnifyingGlassIcon = true;

    this.rnUnmappedUsersSearch.AffectedOrganizationId = this.organizationId;
    this.rnUnmappedUsersSearch.PageNumber = 0;
    this.rnUnmappedUsersSearch.PageSize = 10;
    this.rnUnmappedUsersSearch.IsDescending = false;
    this.rnUnmappedUsersSearch.OrderByText = "orderByFullName";

    this.rnUnmappedUsersSearchPost.AffectedUserId = this.userProfile.userID;
    this.rnUnmappedUsersSearchPost.ConnectPointId =
      this.userProfile?.connectPointID;
    this.rnUnmappedUsersSearchPost.IncludeSubOrgUsers = false;
    this.rnUnmappedUsersSearchPost.PageSize = 10;
    this.rnUnmappedUsersSearchPost.OrderByText = "orderByFullName";
    this.rnUnmappedUsersSearchPost.IsDescending = false;

    this.orgNameFolderName = this.userProfile?.organizationName;
    this.componentLoaded.emit(true);

    //all this extra stuff on input search text is for debouncing
    this.localParams.inputSearchText = new Subject<string>();
    this.localParams.inputSearchText
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((inputText) => {
        this.localParams.currentSearchText = inputText;
        this.getUnmappedOrgsAndUsers(this.localParams.currentSearchText);
      });
  }

  getUnmappedOrgsAndUsers(searchText: string) {
    if (searchText) {
      this.isLoading = true;
      // Clearing out the list and showing the overlay before running the
      // query in the RNAPI2
      this.rnGrantAccessSearchResultVM = [];
      const pe = new PointerEvent("mouse");
      this.overlayPanel.show(pe);
      const rnUnmappedUsersSearch = new RnUnmappedUsersSearchSort();
      rnUnmappedUsersSearch.AffectedOrganizationId =
        this.userProfile.organizationID;
      rnUnmappedUsersSearch.OrganizationId = this.userProfile.organizationID;
      rnUnmappedUsersSearch.AffectedUserId = this.userProfile.userID;
      rnUnmappedUsersSearch.ConnectPointId = this.userProfile?.connectPointID;
      rnUnmappedUsersSearch.IncludeOrgs = true;
      rnUnmappedUsersSearch.IncludeSubOrgUsers = true;
      rnUnmappedUsersSearch.Keyword = searchText;
      rnUnmappedUsersSearch.PageSize = 10;
      rnUnmappedUsersSearch.PageNumber = 0;
      rnUnmappedUsersSearch.OrderByText = "orderByCategory";
      rnUnmappedUsersSearch.IsDescending = false;
      const unmappedUsers = this.organizationService
        .apiV2OrganizationsGetunmappedusersandorgsforaccessmanagementwithsortandpagingPost(
          rnUnmappedUsersSearch,
        )
        .subscribe((r) => {
          if (r.data) {
            setTimeout(() => {
              this.rnGrantAccessSearchResultVM = r.data.Results;
              this.isLoading = false;
              // Going to show the overlay again in case
              // the user has inadvertently closed it.
              const pe = new PointerEvent("mouse");
              this.overlayPanel.show(pe);
            }, 2000);
          }
        });
      this.subscriptions.push(unmappedUsers);
    } else {
      this.overlayPanel.hide();
    }
  }

  searchOccurred(event): void {
    let newParam;
    this.isOrg = false;
    if (event.data.SearchResultType === "org") {
      this.selecedOrgID = event.data?.Organization?.ID;
      this.rnUnmappedUsersSearchPost.AffectedOrganizationId =
        event.data?.Organization?.ID;
      this.rnUnmappedUsersSearchPost.OrganizationId =
        event.data?.Organization?.ID;
      this.rnUnmappedUsersSearchPost.Keyword = "";
      this.rnUnmappedUsersSearchPost.PageNumber = 0;
      this.rnUnmappedUsersSearchPost.PageSize = 100;
      this.isOrg = true;
      newParam = this.rnUnmappedUsersSearchPost;
    } else {
      newParam = event.data.User;
    }

    this.assignSelectedValue(this.isOrg, newParam);
  }

  rowDataviewO(data, overlay) {
    // console.log(data);
    overlay.hide();
  }

  assignSelectedValue(isOrg, newParam?) {
    if (isOrg) {
      const newListOfUsers = [];
      const unmappedUsers = this.organizationService
        .apiV2OrganizationsGetunmappedusersforaccessmanagementwithsortandpagingPost(
          newParam,
        )
        .subscribe((r) => {
          this.rnGrantAccessSearchResultVMSearchResultsVM = r.data;
          for (
            let i = 0;
            i < this.rnGrantAccessSearchResultVMSearchResultsVM.Results.length;
            i++
          ) {
            newListOfUsers.push(
              this.rnGrantAccessSearchResultVMSearchResultsVM.Results[i].User,
            );
          }

          for (let i = 0; i < newListOfUsers.length; i++) {
            const check = this.lstUser.filter(
              (f) =>
                f.Email === newListOfUsers[i].Email &&
                f.FullName === newListOfUsers[i].FullName &&
                f.OrganizationID === newListOfUsers[i].OrganizationID &&
                f.OrganizationName === newListOfUsers[i].OrganizationName &&
                f.UserID === newListOfUsers[i].UserID &&
                f.UserType === newListOfUsers[i].UserType,
            );
            if (check.length === 0) {
              this.lstUser.push(newListOfUsers[i]);
            }
          }

          this.dataSource = [...this.lstUser];
          if (this.dataSource.length > 0) {
            this.userGrantAccessBtnDisabled = false;
          }
        });
      this.subscriptions.push(unmappedUsers);
    } else {
      let firstDataFeed = false;
      if (this.dataSource) {
        const check = this.lstUser.filter(
          (f) =>
            f.Email === newParam.Email &&
            f.FullName === newParam.FullName &&
            f.OrganizationID === newParam.OrganizationID &&
            f.OrganizationName === newParam.OrganizationName &&
            f.UserID === newParam.UserID &&
            f.UserType === newParam.UserType,
        );

        if (check.length === 0) {
          this.lstUser.push(newParam);
          this.dataSource = [...this.lstUser];
        }
      } else {
        firstDataFeed = true;
        this.lstUser.push(newParam);
        this.dataSource = [...this.lstUser];
      }
      if (this.dataSource.length > 0) {
        this.userGrantAccessBtnDisabled = false;
      }
    }
  }

  onSortUsers(event) {
    this.userTableSortData = new SortData(event.field, event.order);
  }

  removeAt(itemId: number) {
    this.dataSource = this.dataSource.filter((c) => {
      return c.UserID !== itemId;
    });
    if (this.dataSource.length === 0) {
      this.userGrantAccessBtnDisabled = true;
    }
  }

  userPageChanged(event) {
    this.userPageData = event;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  trackByFn(index, element) {
    return index;
  }

  openSearchOverlay(event) {
    this.localParams.inputSearchText.next(event);
  }

  closeSearchOverlay() {
    // close overlay when select from the list
    const overlayExist = document.getElementById("primaryFrameSearchControl");
    if (overlayExist) {
      overlayExist.click();
    }
  }

  grantAccess() {
    this.isProcessing = true;
    const rnDriveMappingMultipleUser = new RnDriveMappingMultipleUser();
    rnDriveMappingMultipleUser.AffectedOrganizationId =
      this.userProfile.organizationID;
    rnDriveMappingMultipleUser.AffectedUserId = this.userProfile.userID;
    rnDriveMappingMultipleUser.ConnectPointId =
      this.userProfile?.connectPointID;
    rnDriveMappingMultipleUser.Ids = (this.dataSource as RnUsersSearchVM[]).map(
      (s) => {
        return s.UserID.toString();
      },
    );

    const grantAccess = this.userService
      .apiV2UsersCreatedrivemappingsPost(rnDriveMappingMultipleUser, "response")
      .subscribe((r) => {
        if (r.body.Success) {
          this.toastService.showSuccess("Successfully saved changes");
          this.rnsidebarService.refreshUser(this.dataSource.length);
        }
      });
    this.subscriptions.push(grantAccess);
  }

  dismiss(): void {
    this.rnsidebarService.hide();
  }

  private subscriptions: Subscription[] = [];
}
export interface Element {
  Name: string;
  Email: string;
  Organization: string;
  //symbol: string;
}
