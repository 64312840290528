<div class="confirmation-header">
  <div>Item</div>
  <div>Monthly Cost</div>
</div>
<div class="confirmation-table-container">
  @for (item of dataSource; track item) {
    <div class="package-row" [ngClass]="{ 'last-row': $last }">
      <div>{{ item.PackageName }}</div>
      <div>${{ item.NextBillPrice | number: "1.2-2" }}</div>
    </div>
  }
</div>
