import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-qbo-confirmation",
  templateUrl: "./qbo-confirmation.component.html",
  styleUrls: ["./qbo-confirmation.component.scss"],
})
export class QboConfirmationComponent {
  @Input() checkoutForm: FormGroup;
  plansValue;
  @Input() set qboCheckout(data: any) {
    this.dataSource = data;
  }
  displayedColumns = ["Item", "Monthly Cost"];
  dataSource = [];
  selectedItems = [];
}
export interface Element {
  PackageName: string;
  NextBillPrice: string;
}
