import { Injectable } from "@angular/core";
import { UserLoginState } from "../../../core/models/user.login.state";
import { Observable, BehaviorSubject } from "rxjs";
import {
  RnOrganizationWLPContactInformationVM,
  RnOrganizationConfigurationVM,
  RnOrganizationsVM,
  RnWLPInformationVM,
  RnOrganizationPaymentOnFileVM,
  RnStringStringDictionaryRNResponseRelay,
} from "../rnapi2-service/models/models";
import { jwtDecode } from "jwt-decode";
import { UserToken } from "../../../core/models/user-token";

@Injectable({
  providedIn: "root",
})
export class LoggedInInfoService {
  private CURRENT_USER_STORAGE_KEY = "user_data";
  private CURRENT_USER_ORGP_PROFILE_KEY = "org_profile";
  private CURRENT_USER_ORG_CONFIG_KEY = "org_config";
  private CURRENT_WLP_INFO_KEY = "wlp_info";
  private CURRENT_WLPCONFIG_INFO_KEY = "wlp_config_info";
  private CURRENT_WLP_CONTACT_INFO_KEY = "wlp_contact_info";
  private CURRENT_TOKEN_STORAGE_KEY = "token";

  constructor() {
    this.LoggedInuserOrgConfig =
      this.LoggedInUserOrgConfigSubject.asObservable();
    this.LoggedInUser = this.LoggedInUserSubject.asObservable();
    this.LoggedInUserOrg = this.LoggedInUserOrgSubject.asObservable();
    this.WLPInfo = this.WLPInfoSubject.asObservable();
    this.WLPConfig = this.WLPConfigSubject.asObservable();
    this.WLPContactInfo = this.WLPContactInfoSubject.asObservable();
    this.LoggedInUserOrgPaymentOnFile =
      this.LoggedInUserOrgPaymentOnFileSubject.asObservable();
  }

  public checkForRefreshInfo(): void {
    this.refreshLoggedInUser();
    this.refreshLoggedInUserOrg();
    this.refreshLoggedInUserOrgConfig();
    this.refreshWLPInfo();
    this.refreshWLPContactInfo();
    this.refreshWLPConfig();
  }

  public refreshLoggedInUser(): void {
    if (this.LoggedInUserOrgConfigSubject.value === null) {
      const temp = this.GetLoggedInUser();
      if (temp) {
        this.SetLoggedInUser(temp);
      }
    }
  }

  public refreshLoggedInUserOrg(): void {
    if (this.LoggedInUserOrgSubject.value === null) {
      const temp = this.GetLoggedInUserOrg();
      if (temp) {
        this.SetLoggedInUserOrg(temp);
      }
    }
  }

  public refreshLoggedInUserOrgConfig(): void {
    if (this.LoggedInUserOrgConfigSubject.value === null) {
      const temp = this.GetLoggedInUserOrgConfig();
      if (temp) {
        this.SetLoggedInUserOrgConfig(temp);
      }
    }
  }

  public refreshWLPInfo(): void {
    if (this.WLPInfoSubject.value === null) {
      const temp = this.GetWLPInfo();
      if (temp) {
        this.SetWLPInfo(temp);
      }
    }
  }

  public refreshWLPConfig(): void {
    if (this.WLPInfoSubject.value === null) {
      const temp = this.GetWLPConfig();
      if (temp) {
        this.SetWLPConfig(temp);
      }
    }
  }

  public refreshWLPContactInfo(): void {
    if (this.WLPContactInfoSubject.value === null) {
      const temp = this.GetWLPContactInfo();
      if (temp) {
        this.SetWLPContactInfo(temp);
      }
    }
  }

  public GetLoggedInUser(): UserLoginState {
    const token = this.GetJSONFromStorage(this.CURRENT_TOKEN_STORAGE_KEY);
    const jsonData = this.GetJSONFromStorage(this.CURRENT_USER_STORAGE_KEY);
    if (token && jsonData) {
      const jsonToken: UserToken = jwtDecode(token);
      let result = new UserLoginState(null);
      result.CopyFromJson(jsonData);
      // ensure whatever roles the user has, it comes from the token
      result.role = jsonToken.role;
      return result;
    } else {
      return undefined;
    }
  }

  public SetLoggedInUser(loggedInUser: UserLoginState): void {
    this.LoggedInUserSubject.next(loggedInUser);
    this.SetObjectToStorage(this.CURRENT_USER_STORAGE_KEY, loggedInUser);
  }

  public GetLoggedInUserOrgConfig(): RnOrganizationConfigurationVM {
    const jsonData = this.GetJSONFromStorage(this.CURRENT_USER_ORG_CONFIG_KEY);
    if (jsonData !== "") {
      return JSON.parse(jsonData);
    } else {
      return undefined;
    }
  }

  public SetLoggedInUserOrg(orgProfile: RnOrganizationsVM): void {
    this.LoggedInUserOrgSubject.next(orgProfile);
    this.SetObjectToStorage(this.CURRENT_USER_ORGP_PROFILE_KEY, orgProfile);
  }

  public GetLoggedInUserOrg(): RnOrganizationsVM {
    const jsonData = this.GetJSONFromStorage(
      this.CURRENT_USER_ORGP_PROFILE_KEY,
    );
    if (jsonData !== "") {
      return JSON.parse(jsonData);
    } else {
      return undefined;
    }
  }

  public SetLoggedInUserOrgConfig(
    orgConfig: RnOrganizationConfigurationVM,
  ): void {
    this.LoggedInUserOrgConfigSubject.next(orgConfig);
    this.SetObjectToStorage(this.CURRENT_USER_ORG_CONFIG_KEY, orgConfig);
  }

  public SetWLPInfo(wlpInfo: RnWLPInformationVM[]) {
    this.WLPInfoSubject.next(wlpInfo);
    this.SetObjectToStorage(this.CURRENT_WLP_INFO_KEY, wlpInfo);
  }

  public SetWLPConfig(WLPConfig: RnStringStringDictionaryRNResponseRelay) {
    this.WLPConfigSubject.next(WLPConfig);
    this.SetObjectToStorage(this.CURRENT_WLPCONFIG_INFO_KEY, WLPConfig);
  }

  public SetLoggedInUserPOF(pof: RnOrganizationPaymentOnFileVM): void {
    this.LoggedInUserOrgPaymentOnFileSubject.next(pof);
  }

  public GetWLPInfo(): RnWLPInformationVM[] {
    const jsonData = this.GetJSONFromStorage(this.CURRENT_WLP_INFO_KEY);
    if (jsonData !== "") {
      return JSON.parse(jsonData);
    } else {
      return [];
    }
  }

  public GetWLPConfig(): RnStringStringDictionaryRNResponseRelay {
    const jsonData = this.GetJSONFromStorage(this.CURRENT_WLPCONFIG_INFO_KEY);
    if (jsonData !== "") {
      return JSON.parse(jsonData);
    } else {
      return undefined;
    }
  }

  public SetWLPContactInfo(
    wlpContactInfo: RnOrganizationWLPContactInformationVM,
  ): void {
    this.WLPContactInfoSubject.next(wlpContactInfo);
    this.SetObjectToStorage(this.CURRENT_WLP_CONTACT_INFO_KEY, wlpContactInfo);
  }

  public GetWLPContactInfo(): RnOrganizationWLPContactInformationVM {
    const jsonData = this.GetJSONFromStorage(this.CURRENT_WLP_CONTACT_INFO_KEY);
    if (jsonData !== "") {
      return JSON.parse(jsonData);
    } else {
      return undefined;
    }
  }

  public LoggedInuserOrgConfig: Observable<RnOrganizationConfigurationVM>;

  private LoggedInUserOrgConfigSubject =
    new BehaviorSubject<RnOrganizationConfigurationVM>(null);

  public LoggedInUser: Observable<UserLoginState>;
  private LoggedInUserSubject = new BehaviorSubject<UserLoginState>(null);

  public LoggedInUserOrg: Observable<RnOrganizationsVM>;
  private LoggedInUserOrgSubject = new BehaviorSubject<RnOrganizationsVM>(null);

  public WLPInfo: Observable<RnWLPInformationVM[]>;
  private WLPInfoSubject = new BehaviorSubject<RnWLPInformationVM[]>(null);

  public WLPConfig: Observable<RnStringStringDictionaryRNResponseRelay>;
  private WLPConfigSubject =
    new BehaviorSubject<RnStringStringDictionaryRNResponseRelay>(null);

  public WLPContactInfo: Observable<RnOrganizationWLPContactInformationVM>;
  private WLPContactInfoSubject =
    new BehaviorSubject<RnOrganizationWLPContactInformationVM>(null);

  public LoggedInUserOrgPaymentOnFile: Observable<RnOrganizationPaymentOnFileVM>;
  private LoggedInUserOrgPaymentOnFileSubject =
    new BehaviorSubject<RnOrganizationPaymentOnFileVM>(null);

  private SetObjectToStorage(key: string, obj: object): void {
    const jsonData = JSON.stringify(obj);
    localStorage.setItem(key, jsonData);
  }

  private GetJSONFromStorage(key: string): string {
    const temp = localStorage.getItem(key);
    return temp ? temp : "";
  }

  // migrated from auth service
  public loggedInUserIsInternal(): boolean {
    const user = this.GetLoggedInUser();
    if (user) {
      return user.IsInternal();
    }
    return false;
  }

  public loggedInUserHasRights(rights: string[]): boolean {
    const user = this.GetLoggedInUser();
    if (user) {
      let hasRights = true;
      if (rights && rights.length > 0) {
        rights.forEach((a) => {
          if (user.role.indexOf(a) < 0) {
            hasRights = false;
          }
        });
      }
      return hasRights;
    }
  }

  public loggedInUserHasOneOfTheseRights(rights: string[]): boolean {
    const user = this.GetLoggedInUser();
    if (user) {
      let hasRights = false;
      if (rights && rights.length > 0) {
        rights.forEach((a) => {
          if (user.role.indexOf(a) >= 0) {
            hasRights = true;
          }
        });
      }
      return hasRights;
    }
  }
}
