import { Injectable } from "@angular/core";
import { SelectionService } from "../selection/selection.service";
import { LoggedInInfoService } from "../loggedInInfo/logged-in-info.service";
import { UserLoginState } from "../../../core/models/user.login.state";
import { Observable, of } from "rxjs";
import { UserTypesVM } from "../../../core/models/UserTypesVM";
import { map } from "rxjs/operators";
import {
  RnUsersProfileVM,
  RnOrganizationsProfileVM,
  RnOrganizationConfigurationVM,
} from "../rnapi2-service/models/models";
import { LookupService } from "../rnapi2-service/apis/api";

@Injectable({
  providedIn: "root",
})
export class OrganizationLogicService {
  public loggedInUser;

  // #region Constructor
  constructor(
    private selectionService: SelectionService,
    private loggedInInfoService: LoggedInInfoService,
    private lookupService: LookupService,
  ) {
    this.selectionService.SelectedOrgProfile.subscribe((p) => {
      this.selectedOrg = p;
    });

    this.selectionService.SelectedOrgConfig.subscribe((c) => {
      this.selectedOrgConfig = c;
    });

    this.selectionService.SelectedTopLevelOrg.subscribe((p) => {
      this.selectedTopLevelOrg = p;
    });

    this.loggedInUser = this.loggedInInfoService.GetLoggedInUser();
  }
  // #endregion
  // #region Organization Ownership Methods
  public LoggedInusersOrgPaysForSelectedSubOrg(): boolean {
    const loggedInUser: UserLoginState =
      this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return false;
    }
    return (
      this.selectedOrg &&
      !loggedInUser.IsInternal() &&
      this.selectedOrg.ParentID > 0 &&
      loggedInUser.OrganizationID === this.selectedOrg.IdThatPaysForThisOrg &&
      this.selectedOrg.ID !== this.selectedOrg.IdThatPaysForThisOrg
    );
  }

  public DoesSelectedOrgPayForItself(): boolean {
    return this.DoesOrgPayForItself(this.selectedOrg);
  }

  public DoesOrgPayForItself(orgProfile: RnOrganizationsProfileVM): boolean {
    return orgProfile.ID === orgProfile.IdThatPaysForThisOrg;
  }

  public DoesSelectedOrgNeedPOF(): boolean {
    return this.DoesOrgNeedPOF(this.selectedOrg, this.selectedOrgConfig);
  }

  public DoesOrgNeedPOF(
    orgProfile: RnOrganizationsProfileVM,
    orgConfig: RnOrganizationConfigurationVM,
  ): boolean {
    if (orgProfile && orgConfig) {
      if (!orgConfig.DisallowDirectBilling) {
        return false;
      }

      if (
        orgConfig.DistributedOrganization &&
        !orgConfig.DisallowCollaboration
      ) {
        return (
          !orgProfile.HasDirectPaymentOnFile &&
          (orgConfig.InitiatedBillingSeparation ||
            !orgProfile.DoesMyParentHaveAPOF)
        );
      }

      return (
        !orgProfile.HasDirectPaymentOnFile &&
        (orgConfig.InitiatedBillingSeparation ||
          orgConfig.DistributedOrganization)
      );
    }
    return false;
  }

  public GetIdThatPaysForThisOrg(): number {
    return this.selectedTopLevelOrg.IdThatPaysForThisOrg;
  }
  // #endregion

  public CanChangeOrganizationStatus(): boolean {
    if (!this.selectedOrg) {
      return false;
    }

    let canUserChangeStatus = false;
    const loggedInUser: UserLoginState =
      this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return false;
    }

    const loggedInUserIsInternal = loggedInUser.IsInternal();
    if (this.selectedOrg.StatusDesc === "WriteOff") {
      canUserChangeStatus = this.loggedInInfoService.loggedInUserHasRights([
        "RMVWOSTORG",
      ]);
    } else {
      canUserChangeStatus =
        this.selectedOrg.IdThatPaysForThisOrg === this.selectedOrg.ID
          ? this.loggedInInfoService.loggedInUserHasRights(["CLOSEORGZN"])
          : this.loggedInInfoService.loggedInUserHasRights(["CLOSESBORG"]);
      if (!loggedInUser.IsInternal() && canUserChangeStatus) {
        canUserChangeStatus =
          loggedInUser.UserType === "Account Owner" &&
          this.selectedOrg.CustomerType === "Rightworks" &&
          (this.selectedOrg.StatusDesc === "Canceled" ||
            this.selectedOrg.StatusDesc === "Active");
      }
    }

    const canChangeOrgStatus =
      this.selectedOrg.StatusDesc !== "Suspended" &&
      (loggedInUserIsInternal ||
        (!loggedInUserIsInternal &&
          (this.selectedOrg.IdThatPaysForThisOrg ===
            loggedInUser.OrganizationID ||
            this.LoggedInusersOrgPaysForSelectedSubOrg())));

    return canUserChangeStatus && canChangeOrgStatus;
  }
  ///To Do when needed
  // showCreateClientOrgButton = function () {
  //   return (this.selectedOrg.StatusDesc !== 'WriteOff'||this.selectedOrg.StatusDesc !== 'Suspended'||this.selectedOrg.StatusDesc !== 'Canceled') &&
  //       this.myOrgPaysForThisOrg() &&
  //       !this.selectedOrg.selectedOrgConfiguration.DisallowSubOrganizations;
  // }

  // showEditOrgInfoButtonWithRenameOrg = function () {
  //   return this.canShowRenameOrg() &&
  //        this.selectedOrg.StatusDesc !== 'WriteOff';
  // }

  // showEditOrgInfoButtonWithoutRenameOrg = function () {
  //   return !this.canShowRenameOrg() &&
  //       !this.selectedOrg.isFolder &&
  //        this.selectedOrg.StatusDesc !== 'WriteOff';
  // }

  // showMergeOrgButton = function () {
  //   return this.canOrgMerge() &&
  //        this.selectedOrg.StatusDesc !== 'WriteOff';
  // }

  // showMergeIntuitToDirectButton = function () {
  //   return  this.selectedOrg.StatusDesc !== 'WriteOff';
  // }

  public myOrgPaysForThisOrg(isInternal: boolean, loggedInUserOrgID: number) {
    // RN "Pays for" everything, just return true if internal
    if (isInternal) {
      return true;
    } else {
      if (this.selectedOrg)
        return this.selectedOrg.IdThatPaysForThisOrg == loggedInUserOrgID;
    }
  }

  public canOrgMerge(
    CREATESUBS: boolean,
    selectedOrgParam: RnOrganizationsProfileVM,
  ): boolean {
    if (selectedOrgParam)
      return (
        selectedOrgParam.CustomerType === "Rightworks" &&
        selectedOrgParam.ParentID === -1 &&
        CREATESUBS &&
        !this.isWriteoff()
      ); //!this.selectedOrg.isFolder &&
  }

  public CanChangeOrganizationWLPStatus(): boolean {
    return (
      this.selectedOrg &&
      this.selectedOrg.CustomerType !== "Rightworks" &&
      this.loggedInInfoService.loggedInUserHasRights(["UPORGWLPST"])
    );
  }

  public canMergeOrgToDirect(
    MRGINTDRCT: boolean,
    selectedOrgParam: RnOrganizationsProfileVM,
  ): boolean {
    return (
      selectedOrgParam?.CustomerType === "Intuit" &&
      MRGINTDRCT &&
      !this.isWriteoff()
    );
  }

  public isWriteoff(): boolean {
    if (this.selectedOrg) return this.selectedOrg.StatusDesc === "WriteOff";
  }

  public showCreateOrgInfoButton(
    CREATESUBS: boolean,
    selectedOrgParam: RnOrganizationsProfileVM,
    SelectedOrgConfigParam: RnOrganizationConfigurationVM,
  ): boolean {
    return (
      CREATESUBS &&
      selectedOrgParam?.StatusDesc !== "Suspended" &&
      selectedOrgParam?.StatusDesc !== "Canceled" &&
      selectedOrgParam?.StatusDesc !== "WriteOff" &&
      !SelectedOrgConfigParam?.DisallowSubOrganizations
    );
  }

  public canShowRenameOrg(
    RNMSUBORG: boolean,
    RENAMEORG: boolean,
    isInternal: boolean,
    loggedInUserOrgID: number,
    selectedOrgParam: RnOrganizationsProfileVM,
  ): boolean {
    if (
      !selectedOrgParam ||
      selectedOrgParam?.StatusDesc === "Suspended" ||
      this.selectedOrgConfig?.DisallowEditingOrgInfo
    ) {
      return false;
    }

    // Top-level WLPs cannot change their name
    if (
      selectedOrgParam?.CustomerType !== "Rightworks" &&
      selectedOrgParam?.ParentID === -1
    ) {
      return false;
    }

    if (
      selectedOrgParam?.ParentID === -1 ||
      selectedOrgParam?.IdThatPaysForThisOrg === selectedOrgParam?.ID
    ) {
      return (
        (isInternal ||
          selectedOrgParam?.IdThatPaysForThisOrg === loggedInUserOrgID) &&
        RENAMEORG
      );
    }
    return this.myOrgPaysForThisOrg(isInternal, loggedInUserOrgID) && RNMSUBORG;
  }

  public showEditOrgInfoButton(
    RNMSUBORG: boolean,
    RENAMEORG: boolean,
    isInternal: boolean,
    loggedInUserOrgID: number,
    selectedOrgParam: RnOrganizationsProfileVM,
  ) {
    const canRename = this.canShowRenameOrg(
      RNMSUBORG,
      RENAMEORG,
      isInternal,
      loggedInUserOrgID,
      selectedOrgParam,
    );
    return (
      (canRename && !this.isWriteoff()) ||
      (!canRename &&
        !this.isWriteoff() &&
        this.loggedInInfoService.loggedInUserHasRights(["ADDUPDTXID"]))
    ); //TODO: second condition needs to add !Folder
  }

  public CanEditFolderName(): boolean {
    const loggedInUser: UserLoginState =
      this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return false;
    }

    const loggedInUserIsInternal = loggedInUser.IsInternal();
    return (
      this.selectedOrg &&
      this.loggedInInfoService.loggedInUserHasRights(["RNMFOLDER"]) &&
      (loggedInUserIsInternal ||
        this.selectedOrg.IdThatPaysForThisOrg === loggedInUser.OrganizationID)
    );
  }

  public CanPurchaseDropPackages(
    PRCHDROPPK: boolean,
    isInternal: boolean,
    selectedOrgParam: RnOrganizationsProfileVM,
    loggedInUsersOrg: RnOrganizationsProfileVM,
  ): boolean {
    return (
      PRCHDROPPK &&
      selectedOrgParam.Status !== "Canceled" &&
      selectedOrgParam.Status !== "AddOnSuspended" &&
      selectedOrgParam.Status !== "WriteOff" &&
      (selectedOrgParam.CustomerType === "Rightworks" ||
        (selectedOrgParam.WlpStatus !== "Canceled" &&
          selectedOrgParam.WlpStatus !== "CancelPending")) &&
      (isInternal ||
        loggedInUsersOrg.ID === selectedOrgParam.IdThatPaysForThisOrg)
    );
  }

  public CanPurchasePackagesNeedPOF(
    PRCHDROPPK: boolean,
    UPDTBILING: boolean,
    isInternal: boolean,
    selectedOrgParam: RnOrganizationsProfileVM,
    selectedOrgConfig: RnOrganizationConfigurationVM,
    loggedInUsersOrg: RnOrganizationsProfileVM,
    loggedInUsersRole: string,
  ): boolean {
    if (PRCHDROPPK && UPDTBILING) {
      if (loggedInUsersOrg.HasDirectPaymentOnFile) {
        return false;
      }
      if (
        selectedOrgParam.IdThatPaysForThisOrg == loggedInUsersOrg.ID &&
        loggedInUsersOrg.ParentID == -1
      ) {
        return true;
      }
    }
    return false;
  }

  public GetRolesForUser(
    userProfile: RnUsersProfileVM,
  ): Observable<UserTypesVM[]> {
    const loggedInUser: UserLoginState =
      this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      const temp = [];
      return of(temp);
    }

    const override =
      loggedInUser.IsInternal &&
      this.loggedInInfoService.loggedInUserHasRights(["CHNGACCOWN"]);
    return this.lookupService
      .apiV2LookupUsertypesGet(-1, userProfile.UserID, override, "response")
      .pipe(
        map((r) => {
          let visibleRoles = [];

          const loggedInUser: UserLoginState =
            this.loggedInInfoService.GetLoggedInUser();
          if (!loggedInUser) {
            return;
          }

          const internalUser = loggedInUser.IsInternal.bind(loggedInUser);
          const orgPaysForSelf = !this.DoesSelectedOrgPayForItself();
          const chngAccOwn = this.loggedInInfoService.loggedInUserHasRights([
            "CHNGACCOWN",
          ]);
          if (!chngAccOwn) {
            visibleRoles = r.data.filter((r) => {
              return r.Name !== "Account Owner";
            });
          } else if (
            internalUser &&
            chngAccOwn &&
            this.selectedOrg.CustomerType !== "Rightworks"
          ) {
            visibleRoles = r.data;
          } else if (
            (internalUser && orgPaysForSelf) ||
            (!internalUser &&
              loggedInUser.UserType === "Account Owner" &&
              loggedInUser.OrganizationID === userProfile.OrganizationID &&
              orgPaysForSelf &&
              loggedInUser.UserID !== userProfile.UserID &&
              this.selectedOrg.CustomerType === "Rightworks")
          ) {
            visibleRoles = r.data;
          } else {
            visibleRoles = r.data;
          }
          visibleRoles = visibleRoles.sort((r1, r2) => {
            const role1 = r1 as UserTypesVM;
            const role2 = r2 as UserTypesVM;
            return role1.Name.localeCompare(role2.Name);
          });

          return visibleRoles;
        }),
      );
  }

  public CanShowPackages(
    VWOTHERSPK: boolean,
    selectedOrg: RnOrganizationsProfileVM,
    selectedOrgConfig: RnOrganizationConfigurationVM,
  ): boolean {
    //TODO: return false if on a Folder: if (selectedItem.IsFolder) { return false; };
    const validOrgState =
      (!selectedOrgConfig.DistributedOrganization &&
        selectedOrg.Status !== "Suspended") ||
      selectedOrgConfig.DistributedOrganization ||
      selectedOrg.Status !== "AddOnSuspended";
    const orgCanShowPackages =
      (!selectedOrgConfig.DistributedOrganization &&
        selectedOrg.HasDirectPaymentOnFile) ||
      (selectedOrgConfig.DistributedOrganization &&
        (selectedOrg.ParentID === -1 || selectedOrg.HasDirectPaymentOnFile));
    return VWOTHERSPK && validOrgState && orgCanShowPackages;
  }

  public CanOpenPackagesAddOns(
    ASNRMALLPK: boolean,
    isInternal: boolean,
    selectedOrg: RnOrganizationsProfileVM,
    loggedInUserOrg: RnOrganizationsProfileVM,
  ): boolean {
    return (
      ASNRMALLPK &&
      (selectedOrg.IdThatPaysForThisOrg === loggedInUserOrg.ID ||
        selectedOrg.IdThatPaysForThisOrg !== selectedOrg.ID ||
        isInternal)
    );
  }

  public CanShowPromoCodeEntry() {
    return (
      this.selectedOrg?.AccountOwnerID > 0 && !this.selectedOrg.HasAPromotion
    );
  }

  public IsOrgActiveWLPStatusCheck(selectedOrg: RnOrganizationsProfileVM) {
    return (
      selectedOrg.Status === "Active" &&
      (!selectedOrg.WlpStatus ||
        selectedOrg.WlpStatus === "" ||
        selectedOrg.WlpStatus === "Active")
    );
  }

  public CanOrgPurchaseLeaseLicenses(
    PRCHDROPPK: boolean,
    selectedOrg: RnOrganizationsProfileVM,
    selectedOrgConfig: RnOrganizationConfigurationVM,
    loggedInUserOrgId: number,
  ): boolean {
    const validState =
      selectedOrg.Status !== "Suspended" &&
      selectedOrg.Status !== "Canceled" &&
      selectedOrg.Status !== "WriteOff" &&
      selectedOrg.Status !== "AddOnSuspended";
    const validWLPState = selectedOrg.WlpStatus !== "CancelPending";
    const canUpdateBilling =
      selectedOrg.HasDirectPaymentOnFile &&
      selectedOrg.ID === loggedInUserOrgId;
    return (
      PRCHDROPPK &&
      !selectedOrgConfig.DisallowLicensePurchases &&
      validState &&
      validWLPState &&
      canUpdateBilling
    );
  }

  public getOrgWithPayInfoID(
    orgProfile: RnOrganizationsProfileVM,
    organizationId: number,
  ) {
    const loggedInUser: UserLoginState =
      this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return false;
    }

    let orgWithPayInfoID = -1;
    if (
      orgProfile.IdThatPaysForThisOrg > 0 &&
      orgProfile.IdThatPaysForThisOrg === loggedInUser.OrganizationID
    ) {
      orgWithPayInfoID = orgProfile.IdThatPaysForThisOrg;
    } else if (
      orgProfile.HasDirectPaymentOnFile &&
      orgProfile.ID == organizationId
    ) {
      orgWithPayInfoID = orgProfile.ID;
    }
    return orgWithPayInfoID;
  }

  public CanSeeOrganizationBilling(
    orgProfile: RnOrganizationsProfileVM,
    orgConfig: RnOrganizationConfigurationVM,
    loggedInUser: UserLoginState,
    UPDTBILING: boolean,
    VWBILLING: boolean,
  ): boolean {
    if (!UPDTBILING && !VWBILLING) return false;

    if (!orgProfile) return false;

    if (orgProfile.HasDirectPaymentOnFile) {
      return orgProfile.ID === loggedInUser.OrganizationID;
    }

    return (
      (orgProfile.ID === loggedInUser.OrganizationID && UPDTBILING) ||
      (orgProfile.ID !== loggedInUser.OrganizationID &&
        !orgConfig.InitiatedBillingSeparation)
    );
  }

  public CanSeeOrganizationSecurity(
    orgProfile: RnOrganizationsProfileVM,
    loggedInUser: UserLoginState,
    UPDTSEC: boolean,
  ): boolean {
    if (!UPDTSEC) return false;

    if (!orgProfile) return false;

    return (
      orgProfile.ID === loggedInUser.OrganizationID &&
      UPDTSEC &&
      (orgProfile.ParentID === -1 || orgProfile.HasDirectPaymentOnFile)
    );
  }

  public HasPOFOrCanCreatePOF(
    orgProfile: RnOrganizationsProfileVM,
    UPDTBILING: boolean,
  ): boolean {
    return (
      orgProfile?.HasDirectPaymentOnFile ||
      orgProfile?.DoesMyParentHaveAPOF ||
      UPDTBILING
    );
  }

  // #region private members
  private selectedTopLevelOrg: RnOrganizationsProfileVM;
  private selectedOrg: RnOrganizationsProfileVM;
  private selectedOrgConfig: RnOrganizationConfigurationVM;
  // #endregion
}
