import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PackageCardData } from "./package-card-data";

@Component({
  selector: "app-package-card",
  templateUrl: "./package-card.component.html",
  styleUrls: ["./package-card.component.scss"],
})
export class PackageCardComponent implements OnInit {
  @Input() Package: PackageCardData;
  @Input() MaxAllowed: number;
  @Input() MaxUsedMessage: string;
  @Input() QtyControlsDisabled: boolean;
  @Input() AddQtyControlsDisabled: boolean;
  @Input() Disabled: boolean = false;
  @Input() DisabledToolTip: string = "";
  @Output() UpdateChanges = new EventEmitter<any>();
  @Output() AddItem = new EventEmitter<PackageCardData>();
  @Output() RemoveItem = new EventEmitter<PackageCardData>();
  @Output() PackageUpdated = new EventEmitter<PackageCardData>();

  @Input() CardType = "changeQuantity";
  @Input() AddOnDisabled: boolean;
  @Input() AddOnTooltip: string;
  @Input() PurchaseRestricted: boolean;
  @Input() InfoOnly = false;
  @Input() InternalExclusiveTooltip: string;

  ShowQuantityChanged: boolean;
  ShowStaticCounts: boolean;
  ShowAddOnRemove: boolean;
  ShowPriceHeading: boolean;
  ShowDescription: boolean;

  ngOnInit(): void {
    this.Package.PackageDescHtml = this.Package.PackageDescHtml.replace(
      "<br>",
      "",
    );
    this.ShowQuantityChanged = this.getShowQuantityChanged();
    this.ShowStaticCounts = this.getShowStaticCounts();
    this.ShowAddOnRemove = this.getShowAddOnRemove();
    this.ShowPriceHeading = this.getShowPriceHeading();
    this.ShowDescription = this.getShowDescription();
    if (!this.QtyControlsDisabled || !this.AddQtyControlsDisabled) {
      // if it's not already disabled, let's turn it off for the InternalExclusive packages
      this.QtyControlsDisabled =
        this.Package.CatalogVisibility === "InternalExclusive";
      this.AddQtyControlsDisabled =
        this.Package.CatalogVisibility === "InternalExclusive";
    }
  }

  public getShowQuantityChanged(): boolean {
    return (
      this.Package &&
      this.Package.PackageID > 0 &&
      this.CardType === "changeQuantity"
    );
  }

  public getShowStaticCounts(): boolean {
    return (
      this.Package &&
      this.Package.PackageID > 0 &&
      this.CardType === "selectable"
    );
  }

  public getShowAddOnRemove(): boolean {
    return (
      this.Package &&
      this.Package.PackageID > 0 &&
      this.CardType === "addRemove"
    );
  }

  quantityChanged(
    pkg: { CurrentQuantity: number; CurrentUsed: number },
    event: number,
  ): void {
    if (pkg.CurrentQuantity + event >= pkg.CurrentUsed) {
      pkg.CurrentQuantity += event;
      this.UpdateChanges.emit(pkg.CurrentQuantity <= 0);
    }
  }

  newQuantityEntered(pkg, event): void {
    if (!(pkg.CurrentUsed > event)) {
      pkg.CurrentQuantity = event;
      this.UpdateChanges.emit();
    }
  }

  AddClick(): void {
    this.AddItem.emit(this.Package);
  }

  RemoveClick(): void {
    this.RemoveItem.emit(this.Package);
  }

  getShowPriceHeading(): boolean {
    return (
      this.CardType !== "license" &&
      this.Package.PackageID > 0 &&
      this.Package.CatalogVisibility !== "Unpurchasable"
    );
  }

  getShowDescription(): boolean {
    return this.Package.CatalogVisibility !== "Unpurchasable";
  }

  IsDisabled(): boolean {
    return (
      this.AddOnDisabled ||
      (this.PurchaseRestricted &&
        this.Package.CurrentQuantity === this.Package.CurrentUsed)
    );
  }
}
